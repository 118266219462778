@import "../../assets/styles/utils";
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        @include res(width,60px,(1 / 2));
        @include res(height,60px,(1 / 2));
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: SourceHanSansCN-Light;
        @include res(font-size,16px,(14 / 16));
        color: #6e6e6e;
        transition: all .3s;
        border-radius: 50%;
        margin-right: 10px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover,&.active {
            background-color: #61ae8f;
            color: #fff;
        }
        span {
            @include res(font-size,30px,(2 / 3));
            font-weight: bold;
        }
    }
}