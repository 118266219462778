@import "../../assets/styles/utils";
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    @include res(background,transparent,(sm:#fff));
    @include res(height,122px,(sm:60px));
    border-bottom: solid 1px rgba(253,254,255,.3);
    transition: all .3s;
    &.on {
        &.active {
            @include res(top,-122px,(sm:0));
        }
        background: #fff;
        .head {
            .logo {
                img {
                    &.img1 {
                        display: none;
                    }
                    &.img2 {
                        display: block;
                    }
                }
            }
            .nav {
                ul {
                    li {
                        a {
                            color: #003520;
                            span {
                                &:after {
                                    background-color: #003520;
                                }
                            }
                        }
                        .search {
                            img {
                                &.img1 {
                                    display: none;
                                }
                                &.img2 {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.head1 {
        background: #fff;
        .head {
            .logo {
                img {
                    &.img1 {
                        display: none;
                    }
                    &.img2 {
                        display: block;
                    }
                }
            }
            .nav {
                ul {
                    li {
                        a {
                            color: #003520;
                            span {
                                &:after {
                                    background-color: #003520;
                                }
                            }
                        }
                        .search {
                            img {
                                &.img1 {
                                    display: none;
                                }
                                &.img2 {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        .logo {
            img {
                @include res(width,fit-content,(sm:65%));
                &.img1 {
                    @include res(display,block,(sm:none));
                }
                &.img2 {
                    @include res(display,none,(sm:block));
                }
            }
        }
        .nav {
            @include res(display,flex,(sm:none));
            height: 100%;
            ul {
                display: flex;
                align-items: center;
                height: 100%;
                li {
                    @include res(margin-right,60px,(lg:30px,mmd:20px));
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        color: #fff;
                        @include res(font-size,20px,(14 / 18));
                        transition: all .3s;
                        font-family: OPPOSans-L;
                        &:last-child {
                            margin-right: 0;
                        }
                        span {
                            position: absolute;
                            height: 2px;
                            display: flex;
                            align-items: center;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            &:after {
                                content: "";
                                width: 100%;
                                height: 2px;
                                display: block;
                                background-color: #fff;
                                z-index: 1;
                                transition: all .3s;
                                transform-origin: center;
                                transform: scaleX(0);
                            }
                        }
                    }
                    .navchild {
                        position: absolute;
                        left: 0;
                        top: 120px;
                        z-index: 9999;
                        display: none;
                        box-sizing: border-box;
                        padding: 15px 0;
                        dl {
                            dt {
                                white-space: nowrap;
                                line-height: normal;
                                position: relative;
                                a {
                                    color: #003520;
                                    font-size: 16px;
                                    transition: all .3s;
                                    display: block;
                                    width: 100%;
                                    padding:5px 10px;
                                    &.on {
                                        &:hover {
                                            color: #61ae8f;
                                        }
                                    }
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                .xiala {
                                    display: none;
                                    position: absolute;
                                    left: 100%;
                                    top: -5px;
                                    background: rgb(255,255,255);
                                    padding: 10px 0;
                                    img {
                                        position: absolute;
                                        top: 15px;
                                        left: -5px;
                                    }
                                    a {
                                        transition: all .3s;
                                        padding: 0 10px;
                                        margin-bottom: 10px;
                                        &:hover {
                                            color: #61ae8f;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover,&.active {
                        a {
                            font-family: OPPOSans-M;
                            span {
                                &:after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                }
            }
        }
        .phone {
            @include res(display,none,(sm:flex));
            position: relative;
            z-index: 1000;
            align-items: center;
        }
    }
    .open-menu {
        @include res(display,none,(sm:block));
        margin-left: 20px;
        position: relative;
        z-index: 100;
        // top: 50%;
        // transform: translateY(-50%);
        // right: 20px;
    }
    .mob-nav {
        width: 100%;
        height: 100vh;
        background-color: #f7f7f7;
        position: fixed;
        top: 60px;
        right: 100%;
        z-index: 99;
        color: #003520;
        box-sizing: border-box;
        overflow-y: auto;
        transition: .4s cubic-bezier(.77,0,.175,1);
        padding-top: 30px;
        .nLi {
            &.active {
                img {
                    transform: rotate(90deg);
                }
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0,73,130,.3);
                margin: 0 20px;
                // padding: 0 20px;
                .arrow {
                    width: 45px;
                    display: flex;
                    justify-content: end;
                }
                a {
                    flex-grow: 1;
                    line-height: 45px;
                    font-size: 16px;
                    width: fit-content;
                    display: block;
                }
            }
        }
        &.active {
            right: 0;
        }
        .sub {
            display: none;
            border-bottom: 1px solid rgba(0,73,130,.3);
            li {
                a {
                    display: block;
                    padding-left: 20px;
                    font-size: 16px;
                    line-height: 40px;
                    color: #003520;
                }
            }
        }
    }
}
.header-bg {
    width: 100%;
    height: 280px;
    position: fixed;
    @include res(top,120px);
    left: 0;
    z-index: 999;
    background: #fff;
    display: none;
}
.subnav {
    position: fixed;
    @include res(top,122px,(xs:60px));
    left: 0;
    width: 100%;
    z-index: 99;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    &.active {
        top: 0;
    }
    ul {
        border-top: 1px solid #c9c9c9;
        border-bottom: 1px solid #c9c9c9;
        display: none;
        align-items: center;
        justify-content: center;
        height: 65px;
        background: #fff;
        &.on {
            @include res(display,flex,(sm:none));
        }
        li {
            font-family: OPPOSans-L;
            font-size: 16px;
            color: #003520;
            transition: all .3s;
            @include res(margin-right,40px,(lg:30px,mmd:20px));
            position: relative;
            height: 100%;
            a {
                position: relative;
                display: block;
                height: 100%;
                display: flex;
                align-items: center;
                span {
                    position: absolute;
                    width: 0;
                    height: 1px;
                    background-color: #61ae8f;
                    bottom: 0;
                    left: 0;
                    transition: all .3s;
                }
            }
            dl {
                position: absolute;
                top: 62px;
                left: 0;
                background: #F8F8F8;
                box-sizing: border-box;
                padding-bottom: 40px;
                width: 300px;
                display: none;
                padding-left: 30px;
                dd {
                    margin-top: 30px;
                    transition: all .3s;
                    &:hover,&.active {
                        color: #61ae8f!important;
                    }
                }
            }
            &:last-child {
                margin-right: 0;
            }
            .second {
                &.active {
                    color: #61ae8f;
                    font-family: OPPOSans-B;
                    font-weight: bold;
                    font-weight: normal;
                    span {
                        width: 100%;
                    }
                }
            }
            &:hover {
                .second {
                    color: #61ae8f;
                    font-family: OPPOSans-B;
                    font-weight: bold;
                    font-weight: normal;
                    span {
                        width: 100%;
                    }
                }
                dl {
                    display: block;
                }
            }
            
        }
    }
}