@font-face {
  font-family: "iconfont"; /* Project id 4593459 */
  src: url('iconfont.woff2?t=1720162290785') format('woff2'),
       url('iconfont.woff?t=1720162290785') format('woff'),
       url('iconfont.ttf?t=1720162290785') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-left:before {
  content: "\e628";
}

.icon-right:before {
  content: "\e642";
}

.icon-yousanjiao:before {
  content: "\e600";
}

.icon-zuosanjiao:before {
  content: "\eabc";
}

.icon-riqi1:before {
  content: "\eae7";
}

