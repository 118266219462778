@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'OPPOSans-M';
  src: url('https://ziti.dongbaqu.com/Open-Sans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-L';
  src: url('https://ziti.dongbaqu.com/Open-Sans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('https://ziti.dongbaqu.com/Open-Sans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-L1';
  src: url('https://ziti.dongbaqu.com/Open-Sans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-R';
  src: url('https://ziti.dongbaqu.com/Open-Sans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-B';
  src: url('../fonts/AktivGroteskBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-H';
  src: url('../fonts/AktivGroteskBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ArialMT';
  src: url('../fonts/ArialMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family:'Microsoft YaHei','微软雅黑', sans-serif;
  overflow-x: hidden;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size,16px,(14 / 16));
}

img {
  display: block;
}
html {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      opacity: 0.2;
      background: #61ae8f;
  }
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: fade(#fff, 30%);
  }
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1576px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
  &.container-support {
    max-width: 1464px;
  }
  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


.nei-banner {
  position: relative;
  @include res(height,auto,(xs:200px));
  @include res(margin-top,187px,(sm:60px));
  transition: all .3s;
  &.active {
    @include res(margin-top,165px,(sm:60px));
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h2 {
    font-family: OPPOSans-H;
    @include res(font-size,48px,(30 / 48));
    @include res(letter-spacing,5px,(3 / 5));
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    z-index: 1;
    font-weight: bold;
  }
  .model {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}
.nei-title {
  width: fit-content;
  margin: auto;
  text-align: center;
  h2 {
    font-family: OPPOSans-H;
    @include res(font-size,66px,(2 / 3));
    letter-spacing: -1px;
    color: #61ae8f;
    // line-height: 30px;
    font-weight: bold;
  }
  p {
    font-family: OPPOSans-L;
    @include res(font-size,18px,(16 / 18));
    letter-spacing: 1px;
	  color: #7b7b7b;
    @include res(margin-top,25px,(3 / 5));
  }

}
.product-top {
  border-bottom: 2px solid #eeeeee;
  @include res(padding-top,35px,(15 / 35));
  @include res(padding-bottom,35px,(15 / 35));
  @include res(margin-bottom,35px,(15 / 35));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include res(flex-wrap,nowrap,(xs:wrap));
  dl {
      display: flex;
      align-items: flex-start;
      font-family: ArialMT;
      color: #a3a3a3;
      line-height: 20px;
      @include res(flex,1,(xs:none));
      @include res(width,fit-content,(xs:100%));
      @include res(margin-bottom,0,(xs:15px));
      dt {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-right: 13px;
          img {
              margin-right: 9px;
          }
      }
      .dd {
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          flex: 1;
          dd {
              font-size: 14px;
              letter-spacing: 1px;
              transition: all .3s;
              &:hover,&.active {
                  color: #7B7B7B;
              }
          }
          span {
              color: #9f9f9f;
              margin: 0 5px;
              &:last-child {
                  display: none;
              }
          }
      }
  }
  .product-search {
      position: relative;
      @include res(width,300px,(mmd:250px,xs:100%));
      @include res(margin-left,30px,(xs:0));
      input {
          width: 100%;
          @include res(height,39px);
          display: block;
          border: solid 1px #c9c9c9;
          font-family: OPPOSans-L;
          @include res(padding-left,15px,(2 / 3));
          box-sizing: border-box;
          letter-spacing: 1px;
          color: #9f9f9f;
          outline: none;
          &::placeholder {
              font-family: OPPOSans-L;
              color: #9f9f9f;
          }
      }
      button {
          position: absolute;
          right: 0;
          border: none;
          outline: none;
          @include res(top,8px);
          background: transparent;
      }
  }
}