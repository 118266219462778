@import "../../assets/styles/utils";
footer {
    background-color: #323232;
    box-sizing: border-box                                 ;
    .content {
        @include res(width,1510px,(lg:90%,xs:98%));
        margin: auto;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        box-sizing: border-box;
        @include res(flex-wrap,nowrap,(md:wrap));
        @include res(padding-top,103px,(25 / 103));
        @include res(padding-bottom,107px,(25 / 107));
        .logo {
            @include res(margin-right,20px);
            @include res(margin-bottom,0,(md:20px));
        }
        .dl {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            dl {
                @include res(margin-right,30px,(1 / 2));
                @include res(margin-bottom,20px);
                &:last-child {
                    margin-right: 0;
                }
                dt {
                    font-family: OPPOSans-L;
                    @include res(font-size,19px,(16 / 19));
                    color: #ffffff;
                    letter-spacing: 1px;
                    @include res(margin-bottom,45px,(1 / 3));
                }
                dd {
                    font-family: OPPOSans-L;
                    font-size: 14px;
                    transition: all .3s;
                    color: rgba(255,255,255,.5);
                    @include res(margin-bottom,17px,(10 / 17));
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        color: #fff;
                    }
                }
                &:last-child {
                    dd {
                        display: flex;
                        align-items: flex-start;
                        line-height: 20px;
                        .img {
                            width: 25px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                            img {
                                width: auto;
                                height: 20px;
                                filter: brightness(0.5);
                                transition: all .3s;
                            }
                        }
                        &:hover {
                            .img {
                                img {
                                    filter: brightness(1);
                                }
                            }
                        }
                    }
                }
            }
        }
        .ewm {
            p {
                font-family: SourceHanSansCN-Light;
                font-size: 14px;
                color: rgba(255,255,255,.65);
                text-align: center;
                margin-top: 23px;
            }
        }
    }
    .copy {
        width: 100%;
        background: #434343;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: wrap;
        @include res(padding-left,205px,(lg:10%,xs:1%));
        @include res(padding-right,205px,(lg:10%,xs:1%));
        @include res(padding-top,28px,(15 / 28));
        @include res(padding-bottom,28px,(15 / 28));
        .left {
            color: rgba(255,255,255,.65);
            font-family: SourceHanSansCN-Light;
            font-size: 14px;
            a {
                transition: all .3s;
                margin-left: 10px;
                &:hover {
                    color: #fff;
                }
            }
        }
        .right {
            color: rgba(255,255,255,.65);
            font-family: SourceHanSansCN-Light;
            font-size: 14px;
            a {
                transition: all .3s;
                margin-left: 10px;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
.cookie {
    background-color: #fff;
    color: #070032;
    bottom: 16px;
    left: 16px;
    padding: 20px 30px;
    width: 400px;
    border-radius: 16px;
    border: 2px solid #61ae8f;
    font-size: 16px;
    position: fixed;
    z-index: 99999;
    box-sizing: border-box;
    width: 400px;
    height: 200px;
    line-height: 25px;
    display: none;
    button {
        width: 86px;
        height: 43px;
        border-radius: 20px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .3s;
        border: 1px solid #61ae8f;
        background: transparent;
        margin-top: 25px;
        &:hover {
            background: #61ae8f;
            color: #fff;
        }
    }
}