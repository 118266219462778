.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #61ae8f;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination li {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SourceHanSansCN-Light;
  font-size: 16px;
  color: #6e6e6e;
  transition: all .3s;
  border-radius: 50%;
  margin-right: 10px;
}

@media (max-width: 1600px) {
  .pagination li {
    width: 54px;
  }
}

@media (max-width: 1366px) {
  .pagination li {
    width: 48px;
  }
}

@media (max-width: 1024px) {
  .pagination li {
    width: 42px;
  }
}

@media (max-width: 991px) {
  .pagination li {
    width: 36px;
  }
}

@media (max-width: 767px) {
  .pagination li {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .pagination li {
    height: 54px;
  }
}

@media (max-width: 1366px) {
  .pagination li {
    height: 48px;
  }
}

@media (max-width: 1024px) {
  .pagination li {
    height: 42px;
  }
}

@media (max-width: 991px) {
  .pagination li {
    height: 36px;
  }
}

@media (max-width: 767px) {
  .pagination li {
    height: 30px;
  }
}

@media (max-width: 1600px) {
  .pagination li {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .pagination li {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .pagination li {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .pagination li {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .pagination li {
    font-size: 14px;
  }
}

.pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.pagination li:last-child {
  margin-right: 0;
}

.pagination li:hover, .pagination li.active {
  background-color: #61ae8f;
  color: #fff;
}

.pagination li span {
  font-size: 30px;
  font-weight: bold;
}

@media (max-width: 1600px) {
  .pagination li span {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .pagination li span {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .pagination li span {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .pagination li span {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .pagination li span {
    font-size: 20px;
  }
}
